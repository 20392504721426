import React from 'react';
import { Image, Text, Stack, DefaultButton } from '@fluentui/react';
import './NoPage.css';
import Closed from "../assets/closed.png";

const NoPage: React.FC = () => {
    const handleBackToHome = () => {
        window.location.href = 'https://kust.ai/';
    };

    return (
        <Stack className="container" tokens={{ childrenGap: 20 }} verticalAlign="center" horizontalAlign="center">
            <Text variant="xxLarge" className="heading">Closed</Text>
            <Image src={Closed} alt="404 Icon" />
            <Text variant="large" className="text">Ticket closed</Text>
            <DefaultButton className="button" onClick={handleBackToHome}>
                Back to Home
            </DefaultButton>
        </Stack>
    );
};

export default NoPage;
