import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import { initializeIcons } from "@fluentui/react";
import { Routes, Route, HashRouter } from "react-router-dom";
import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Closed from "./pages/Closed";
import Chat from "./pages/chat/Chat";
import { useCookies } from "react-cookie";
import { fetchTokenValidation } from "./api";
import EventContext from "./EventContext";
import ChatWidget from "./components/chatWidget/chatWidget";
import WidgetLoader from "./components/chatWidget/dynamicBehavoiur/WidgetLoader";
import { SystemMessageProvider } from "./components/SystemMessage/SystemMessageContext";
import FullscreenWidgetLoader from "./components/chatWidget/dynamicBehavoiur/FullscrenWidgetLoader";
import { HelpProvider } from "./components/HelpContext/helpContext";

initializeIcons();

export default function App() {
    const [eventTriggered, setEventTriggered] = React.useState(false);
    const [closeTriggered, setEventCloseTriggered] = React.useState(false);

    const triggerEvent = () => {
        setEventTriggered(true);
    };
    const closeEvent = () => {
        setEventCloseTriggered(true);
    };
    

    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    //clean all
    const abortFuncs = useRef([] as AbortController[]);
    const queryParams = new URLSearchParams(window.location.search);
    const sstoken = queryParams.get('sstoken');

    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);
    if (sstoken) {
        fetchTokenValidation(sstoken, abortController.signal).then((res) => {
            if (res) {
                setCookie('accessToken', res.token);
                sessionStorage.setItem('helpMode', JSON.stringify({ help: true, role: res.role }));
                //redirect to /chat/:id using react-router-dom
                window.location.href = window.location.origin + '/#/chat/' + res.web_id;
            }
        }).catch((e) => {
            console.log(e);
        });

        // TODO:
        // The token in the begining should have short excpiration (10 min) but refresh with activity. 
        // renew the token if is not expire (API)
        // if I have a 401 always clean session (add middleware)
    }

    return (
        <EventContext.Provider value={{ eventTriggered, closeTriggered, triggerEvent, closeEvent }}>
        <HelpProvider>
            <SystemMessageProvider>
                <HashRouter>
                    <Routes>
                        <Route path="/" element={<Layout />} >
                            <Route path="chat/:id" element={<Chat />} />
                        </Route>
                        <Route path="/widget" element={<WidgetLoader />} />
                        <Route path="/widget-fs" element={<FullscreenWidgetLoader />} />
                        <Route path="closed" element={<Closed />} />
                        <Route path="*" element={<NoPage />} />
                    </Routes>
                </HashRouter>
            </SystemMessageProvider>
        </HelpProvider>
        </EventContext.Provider>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <FluentProvider theme={teamsLightTheme} style={{ height: "100%" }}>
        <App />
    </FluentProvider>
);
