import React from 'react';
import ChatWidget from '../chatWidget';

function FullscreenWidgetLoader() {
    const hashParams = new URLSearchParams(window.location.hash.split('?')[1]);
    const userSessionID = hashParams.get('id');

    if (!userSessionID) {
        return <div>Error: No user session ID provided</div>;
    }

    return <ChatWidget id={userSessionID} />;
}

export default FullscreenWidgetLoader;
