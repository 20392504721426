import { useRef, useState, useEffect, useContext } from "react";
import { Stack } from "@fluentui/react";
import { BroomRegular, DismissRegular, SquareRegular, ShieldLockRegular, ErrorCircleRegular } from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";

import styles from "./Chat.module.css";
import Azure from "../../assets/Azure.svg";
import Seaver_ from "../../assets/icon_kust.svg";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
    ChatMessage,
    ConversationRequest,
    conversationApi,
    Citation,
    ToolMessageContent,
    ChatResponse,
    getUserInfo,
    ConversationSaveRequest,
    Role,
    fetchConversationApi,
    createTicketSession,
    getConversationMessages,
} from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { increaseMessageCounter, SESSION_ID } from "../../services/temp-session.service";
import { useCookies } from "react-cookie";
import React from "react";
import EventContext from "../../EventContext";
import { SessionError } from "../../errors/session.exception";
import { HelpProvider, useHelp } from "../../components/HelpContext/helpContext";
import { SystemMessageProvider, useSystemMessage } from "../../components/SystemMessage/SystemMessageContext";
import { Message } from "../../models/Message";


// const manageSession = async (sstoken: string | null): Promise<boolean> => {
//     if(sstoken){
//         //validate 
//         return true
//     }
//     return false
// }
const Chat = ({ parentToChild }: any) => {
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);

    const { closeEvent, eventTriggered, triggerEvent } = useContext(EventContext);

    const { systemMessage, setSystemMessage } = useSystemMessage();
    const { helpMode } = useHelp();

    const { id } = useParams();
    const navigate = useNavigate();

    const location = useLocation();
    const [webId, setWebId] = useState<string | undefined>(undefined);



    React.useEffect(() => {
        
        if (eventTriggered) {
            // const helped: boolean = JSON.parse(helpMode).role == "support"
            setIsHelpMode(true);
            startIntervalConversation();
            // setIsSupportRole(JSON.parse(helpMode).role == "support")
            // change the gelp shit
            // Do something when the event is triggered
        }
    }, [eventTriggered]);
    // const sstkn = queryParams.get('sstkn');

    // manageSession(sstkn)
    // const isExistSession = useRef<boolean>(false);

    useEffect(() => {
        if (helpMode && helpMode.help && helpMode.role == 'support') {
            setIsHelpMode(true)
            loadConversation();
        }
    }, [helpMode]);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [isPageReady, setIsPageReady] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    const [activeCitation, setActiveCitation] = useState<[content: string, id: string, title: string, filepath: string, url: string, metadata: string]>();
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
    const [answers, setAnswers] = useState<ChatMessage[]>([]);
    const abortFuncs = useRef([] as AbortController[]);
    const [showAuthMessage, setShowAuthMessage] = useState<boolean>(true);
    const [isHelpMode, setIsHelpMode] = useState<boolean>(false);
    const [isSupportRole, setIsSupportRole] = useState<boolean>(false);


    const [intervalId, setIntervalId] = useState<any>(null);
    const checkSessionError = async (exception: any) => {
        if (exception instanceof SessionError) {
            closeSession();
        }
    }
    const startIntervalConversation = () => {
        if (!intervalId) {
            // loadConversation(); // Call the function initially
            const id = setInterval(loadConversation, 20 * 1000); // 30 seconds in milliseconds
            setIntervalId(id);
        }
    };

    const cancelIntervalConversation = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
    };

    const getUserInfoList = async () => {
        const userInfoList = await getUserInfo();
        // makeApiRequest("Hello")
        // if (userInfoList.length === 0 && window.location.hostname !== "127.0.0.1") {
        setShowAuthMessage(false);


        // }
        // else {
        //     setShowAuthMessage(false);
        // }
    }
    const setHelpMode = (helpMode: any) => {
        const helped: boolean = JSON.parse(helpMode).role == "support"
        setIsHelpMode(helped);
        setIsSupportRole(JSON.parse(helpMode).role == "support")
    }

    const onLoadSession = () => {
        const helpMode = sessionStorage.getItem('helpMode');
        if (helpMode) {
            setHelpMode(helpMode)
            loadConversation();
            startIntervalConversation();
        }
    };
    // window.addEventListener('storage', onLoadSession);
    const closeSession = (navigateClose = true) => {
        removeCookie('accessToken');
        sessionStorage.clear();
        cancelIntervalConversation();
        closeEvent();
        return navigateClose ? navigate('/closed') : navigate('/404');
    }
    const loadConversation = async () => {
        try {
            setIsLoading(true);
            const abortController = new AbortController();
            abortFuncs.current.unshift(abortController);

            const { messages, ticket_status, helped } = await getConversationMessages(abortController.signal);
            if (ticket_status === 'closed') {
                closeSession();
            }
            if (helped) {
                triggerEvent()
                sessionStorage.setItem('helpMode', JSON.stringify({help: true, role:'user'}));
            }
            if (messages?.length !== 0) {
                setAnswers(messages);
                lastQuestionRef.current = messages[messages?.length - 1].content;

                const supportMessageExists = messages.some((message: Message) => message.role === 'support');
                if (supportMessageExists) {
                    setSystemMessage('');
                }
            }
            const helpMode = sessionStorage.getItem('helpMode');
            if (helpMode) {
                const helped = JSON.parse(helpMode).help;
                setIsHelpMode(helped);
                setIsSupportRole(JSON.parse(helpMode).role === 'support');
            }
        } catch (e) {
            checkSessionError(e);
        } finally {
            setIsLoading(false);
        }
    };


    const sendQuestionRequest = async (question: string) => {
        await checkSession();
        if (!isHelpMode) {
            return makeGPTApiRequest(question)
        }
        return sendSupportRequest(question)
    }
    const sendSupportRequest = async (question: string) => {
        lastQuestionRef.current = question;
        setIsLoading(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);
        try {
            // setShowLoadingMessage(true);
            const userMessage: ChatMessage = {
                role: isSupportRole ? Role.SUPPORT : Role.USER,
                content: question
            };

            // let result = {} as ChatResponse;
            let tempArr = [...answers, userMessage]
            setAnswers(tempArr);
            // TODO replace with ticket id the session id
            const requestSave: ConversationSaveRequest = {
                message_id: tempArr.length > 0 ? tempArr.length - 2 : 0,
                message: userMessage.content,
                session_id: SESSION_ID.toString(),
                role: isSupportRole ? Role.SUPPORT : Role.USER
            };

            await fetchConversationApi(requestSave, abortController.signal);
            chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
            // setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);

        } catch (e) {
            checkSessionError(e)
        } finally {
            setIsLoading(false);
            return abortController.abort();
        }
    }
    const makeGPTApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const userMessage: ChatMessage = {
            role: "user",
            content: question
        };
        const request: ConversationRequest = {
            messages: [...answers.filter((answer) => answer.role !== "error"), userMessage]
        };

        let result = {} as ChatResponse;
        let convArr: ChatMessage[] = []
        try {
            const response = await conversationApi(request, abortController.signal);
            if (response?.body) {

                const reader = response.body.getReader();
                let runningText = "";
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            runningText += obj;
                            result = JSON.parse(runningText);
                            setAnswers([...answers, userMessage, ...result.choices[0].messages]);
                            runningText = "";
                            setShowLoadingMessage(false);
                        }
                        catch { }
                    });
                }
                let tempArr = [...answers, userMessage, ...result.choices[0].messages]
                const requestSave: ConversationSaveRequest = createRequestSave(tempArr, userMessage);
                const requestSaveAnswer: ConversationSaveRequest = createAnswerSave(tempArr, result);
                await fetchConversationApi(requestSave, abortController.signal);
                await fetchConversationApi(requestSaveAnswer, abortController.signal);
                setAnswers(tempArr);
            }

        } catch (e) {
            checkSessionError(e)
            if (!abortController.signal.aborted) {
                console.error(result);
                let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let tempArr = [...answers, userMessage, {
                    role: "error",
                    content: errorMessage
                }]
                setAnswers(tempArr);
                const requestSave: ConversationSaveRequest = {
                    message_id: tempArr.length > 0 ? tempArr.length - 2 : 0,
                    message: userMessage.content,
                    session_id: SESSION_ID.toString(),
                    role: Role.USER
                };
                await fetchConversationApi(requestSave, abortController.signal);

                const requestSaveAnswer: ConversationSaveRequest = {
                    message_id: tempArr.length > 0 ? tempArr.length - 1 : 0,
                    message: errorMessage,
                    session_id: SESSION_ID.toString(),
                    role: Role.ERROR
                };
                await fetchConversationApi(requestSaveAnswer, abortController.signal);

            } else {
                let tempArr = [...answers, userMessage]
                setAnswers(tempArr);
                const requestSave: ConversationSaveRequest = {
                    message_id: tempArr.length > 0 ? tempArr.length - 1 : 0,
                    message: userMessage.content,
                    session_id: SESSION_ID.toString(),
                    role: Role.USER
                };
                await fetchConversationApi(requestSave, abortController.signal);
            }
        } finally {
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
        }

        return abortController.abort();
    };
    const createRequestSave = (convArr: any, userMessage: any): ConversationSaveRequest => {
        const request: ConversationSaveRequest = {
            message_id: convArr.length > 0 ? convArr.length - 2 : 0,
            message: userMessage.content,
            // TODO delete session_id, in the API replace for the webticket_id
            session_id: SESSION_ID.toString(),
            role: Role.USER
        };
        return request
    }
    const createAnswerSave = (convArr: any[], result: any): ConversationSaveRequest => {
        const request: ConversationSaveRequest = {
            message_id: convArr.length > 0 ? convArr.length - 1 : 0,
            message: result.choices[0].messages.length > 2 ? result.choices[0].messages[1].content : result.choices[0].messages[0].content,
            session_id: SESSION_ID.toString(),
            role: Role.ASSISTANT
        };
        return request
    }
    // makeApiRequest("Hello")
    // const clearChat = () => {
    //     lastQuestionRef.current = "";
    //     setActiveCitation(undefined);
    //     setAnswers([]);
    // };

    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setShowLoadingMessage(false);
        setIsLoading(false);
    }

    // useEffect(() => {
    //     getUserInfoList();
    // }, []);


    useEffect(() => {
        setShowAuthMessage(false);
        setIsPageReady(false)
        const fetchData = async () => {
            try {
                if (!id) {
                    navigate("/404");
                } else {
                    const helpMode = sessionStorage.getItem('helpMode');
                    const helpObj: any = helpMode ? JSON.parse(helpMode) : null
                    
                    if (helpObj?.role == 'support' && cookies?.accessToken) {
                        onLoadSession();
                        setIsPageReady(true)
                        return cookies.accessToken
                    }
                    setWebId(id!)
                    setIsPageReady(!!id)
                    setIsHelpMode(false)
                    removeCookie('accessToken');
                    setCookie('accessToken', null);
                    cookies.accessToken = null
                    sessionStorage.clear();
                    // const token = await validateId(id);
                    // if (!helpMode || helpObj?.role == 'user') {
                    //     setIsHelpMode(false)
                    //     removeCookie('accessToken');
                    //     setCookie('accessToken', null);
                    //     cookies.accessToken = null
                    //     sessionStorage.clear();
                    // }
                    // setCookie('accessToken', token);
                    // setIsPageReady(!!token)
                    // if (!token) {
                    //     closeSession(false)
                    //     // navigate("/404");
                    // }
                }
            } catch (error) {
                checkSessionError(error)
                console.error("Error validating ID:", error);
                navigate("/404");
            }
        };
        fetchData();
    }, [id, navigate]);

    const checkSession = async () => {
 
        try{
            if (cookies?.accessToken)
                return

            if(!webId)
                return

            const helpMode = sessionStorage?.getItem('helpMode');
            const helpObj: any = helpMode ? JSON.parse(helpMode) : null

            if (!helpMode || helpObj?.role == 'user') {
                setIsHelpMode(false)
                removeCookie('accessToken');
                setCookie('accessToken', null);
                cookies.accessToken = null
                sessionStorage.clear();
            }

            const token = await validateId(webId!);
            if (!token) 
                return closeSession(false)

            setCookie('accessToken', token);
            startIntervalConversation();
        }catch(e){
            console.log(e)
        }


    }
    // useEffect(() =>{
    //check storage and check if I asked for help (if request is correct, set the variable in storage)
    // once variable is setted, gpt is desactivated, we change a header bar that soon will be contacted bycustomer support
    // we set a cookie also with the id of the ticket, in case user want to comeback later , he could put his email and we will send this ticket id to his email with a link
    // In the cookie and DB, we have to connect the temp user with the ticketid, and email and add a role as user (client) and not user admin
    // the chat will wait to someone answer first to me write each time. 
    // For the other side, the user connnected by the customer link will havve to be recognized as support role
    // this role will lead converstion and can close ticket, normal user cant
    // this role will be able to write back. Each time conversation is updated should be stored on DB
    // If you click on the link on the email can come back to conversation in every moment until close. The link for support will have different role than link to user.
    // do changes on token generator and model db
    // Once ticket is closed, cache and coockies will be setted and close session
    // });
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [showLoadingMessage]);

    const onShowCitation = (citation: Citation) => {
        setActiveCitation([citation.content, citation.id, citation.title ?? "", citation.filepath ?? "", "", ""]);
        setIsCitationPanelOpen(true);
    };

    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message.role === "tool") {
            try {
                const toolMessage = JSON.parse(message.content) as ToolMessageContent;
                return toolMessage.citations;
            }
            catch {
                return [];
            }
        }
        return [];
    }
    const validateId = async (webId?: string): Promise<string | undefined> => {
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);
        if (webId) {
            try {
                const createWebTicket = await createTicketSession({ web_id: webId }, abortController.signal)
                return createWebTicket.token
            } catch (e) {
                checkSessionError(e)
            } finally {
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            }
        }
    }


    return isPageReady ? (
        <HelpProvider>
            <SystemMessageProvider>
                <div className={styles.container} role="main">
                    {showAuthMessage ? (
                        <Stack className={styles.chatEmptyState}>
                            <ShieldLockRegular className={styles.chatIcon} style={{ color: 'darkorange', height: "200px", width: "200px" }} />
                            <h1 className={styles.chatEmptyStateTitle}>Authentication Not Configured</h1>
                            <h2 className={styles.chatEmptyStateSubtitle}>
                                This app does not have authentication configured. Please add an identity provider by finding your app in the
                                <a href="https://portal.azure.com/" target="_blank"> Azure Portal </a>
                                and following
                                <a href="https://learn.microsoft.com/en-us/azure/app-service/scenario-secure-app-authentication-app-service#3-configure-authentication-and-authorization" target="_blank"> these instructions</a>.
                            </h2>
                            <h2 className={styles.chatEmptyStateSubtitle} style={{ fontSize: "20px" }}><strong>Authentication configuration takes a few minutes to apply. </strong></h2>
                            <h2 className={styles.chatEmptyStateSubtitle} style={{ fontSize: "20px" }}><strong>If you deployed in the last 10 minutes, please wait and reload the page after 10 minutes.</strong></h2>
                        </Stack>
                    ) : (
                        <Stack horizontal className={styles.chatRoot}>
                            <div className={styles.chatContainer}>
                                {!lastQuestionRef.current ? (
                                    <Stack className={styles.chatEmptyState}>
                                        <img
                                            src={Seaver_}
                                            className={styles.chatIcon}
                                            aria-hidden="true"
                                        />
                                        <h1 className={styles.chatEmptyStateTitle}>Start chatting</h1>
                                        <h2 className={styles.chatEmptyStateSubtitle}>Make a question to our customer service chatbot</h2>
                                    </Stack>
                                ) : (
                                    <div className={styles.chatMessageStream} style={{ marginBottom: isLoading && !isHelpMode ? "40px" : "0px" }} role="log">
                                        {

                                            answers.map((answer, index) => (
                                                <>
                                                    {answer.role === "user" ? (
                                                        <div className={(isSupportRole ? styles.chatMessageGpt : styles.chatMessageUser)} tabIndex={0}>
                                                            <div style={isSupportRole ? { background: "#afcbe6" } : {}} className={styles.chatMessageUserMessage}>{answer.content}</div>
                                                        </div>
                                                    ) : answer.role === "support" ? (
                                                        <div className={(!isSupportRole ? styles.chatMessageGpt : styles.chatMessageUser)} tabIndex={0}>
                                                            <div style={!isSupportRole ? { background: "#afcbe6" } : {}} className={styles.chatMessageUserMessage}>{answer.content}</div>
                                                        </div>
                                                    ) :
                                                        answer.role === "assistant" ? (
                                                            <div className={!isSupportRole ? styles.chatMessageGpt : styles.chatMessageUser}>
                                                                <Answer
                                                                    answer={{
                                                                        answer: answer.content,
                                                                        citations: parseCitationFromMessage(answers[index - 1]),
                                                                    }}
                                                                    answers={answers}
                                                                    pos={index}
                                                                    onCitationClicked={c => onShowCitation(c)}
                                                                />
                                                            </div>
                                                        ) : answer.role === "system" || answer.role === "error" ? (
                                                            <div className={styles.chatMessageGpt}>
                                                                <Answer
                                                                    answer={{
                                                                        answer: answer.content,
                                                                        citations: parseCitationFromMessage(answers[index - 1]),
                                                                    }}
                                                                    answers={answers}
                                                                    pos={index}
                                                                    onCitationClicked={c => onShowCitation(c)}
                                                                />
                                                            </div>
                                                        ) : null}
                                                </>
                                            ))}
                                        {showLoadingMessage && (
                                            <>
                                                <div className={styles.chatMessageUser}>
                                                    <div className={styles.chatMessageUserMessage}>{lastQuestionRef.current}</div>
                                                </div>
                                                <div className={styles.chatMessageGpt}>
                                                    <Answer
                                                        answer={{
                                                            answer: "Generating answer...",
                                                            citations: []
                                                        }}
                                                        answers={[]}
                                                        pos={0}
                                                        onCitationClicked={() => null}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <div ref={chatMessageStreamEnd} />
                                        {systemMessage && (
                                            <div className={styles.systemMessage}>{systemMessage}</div>
                                        )}
                                    </div>
                                )}

                                <Stack horizontal className={styles.chatInput}>
                                    {isLoading && !isHelpMode && (
                                        <Stack
                                            horizontal
                                            className={styles.stopGeneratingContainer}
                                            role="button"
                                            aria-label="Stop generating"
                                            tabIndex={0}
                                            onClick={stopGenerating}
                                            onKeyDown={e => e.key === "Enter" || e.key === " " ? stopGenerating() : null}
                                        >
                                            <SquareRegular className={styles.stopGeneratingIcon} aria-hidden="true" />
                                            <span className={styles.stopGeneratingText} aria-hidden="true">Stop generating</span>
                                        </Stack>
                                    )}
                                    {/* <div
                                role="button"
                                tabIndex={0}
                                onClick={clearChat}
                                onKeyDown={e => e.key === "Enter" || e.key === " " ? clearChat() : null}
                                aria-label="Clear session"
                                >
                                <BroomRegular
                                    className={styles.clearChatBroom}
                                    style={{ background: isLoading || answers.length === 0 ? "#BDBDBD" : "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)", 
                                            cursor: isLoading || answers.length === 0 ? "" : "pointer"}}
                                    aria-hidden="true"
                                />
                            </div> */}
                                    <QuestionInput
                                        clearOnSend
                                        placeholder="Type a new question..."
                                        disabled={isLoading}
                                        onSend={question => sendQuestionRequest(question)}
                                    />
                                </Stack>
                            </div>
                            {answers?.length > 0 && isCitationPanelOpen && activeCitation && (
                                <Stack.Item className={styles.citationPanel} tabIndex={0} role="tabpanel" aria-label="Citations Panel">
                                    <Stack horizontal className={styles.citationPanelHeaderContainer} horizontalAlign="space-between" verticalAlign="center">
                                        <span className={styles.citationPanelHeader}>Citations</span>
                                        <DismissRegular className={styles.citationPanelDismiss} onClick={() => setIsCitationPanelOpen(false)} />
                                    </Stack>
                                    <h5 className={styles.citationPanelTitle} tabIndex={0}>{activeCitation[2]}</h5>
                                    <div tabIndex={0}>
                                        <ReactMarkdown
                                            linkTarget="_blank"
                                            className={styles.citationPanelContent}
                                            children={activeCitation[0]}
                                            remarkPlugins={[remarkGfm]}
                                            rehypePlugins={[rehypeRaw]}
                                        />
                                    </div>

                                </Stack.Item>
                            )}
                        </Stack>
                    )}
                </div>
            </SystemMessageProvider>
        </HelpProvider>
    ) : (<div></div>);
};

export default Chat;
