import { useEffect, useMemo, useRef, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks"
import { FontIcon, Stack, Text } from "@fluentui/react";

import styles from "./Answer.module.css";

import { AskResponse, ChatMessage, ChatResponse, Citation, ConversationSaveRequest, FeedbackSaveRequest, fetchFeedbackApi, Role } from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'

import LikeB from "../../assets/like-b.png";
import LikeW from "../../assets/like-w.png";
import DisLikeB from "../../assets/dislike-b.png";
import DisLikeW from "../../assets/dislike-w.png";
import { getCurrentCounter, increaseMessageCounter, SESSION_ID } from "../../services/temp-session.service";

interface Props {
    answer: AskResponse;
    answers: ChatMessage[];
    pos: number;
    onCitationClicked: (citedDocument: Citation) => void;
}

export const Answer = ({
    answer,
    answers,
    pos,
    onCitationClicked
}: Props) => {
    const abortFuncs = useRef([] as AbortController[]);
    
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);

    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);

    const toggleLike = async() => {

        setIsLiked(!isLiked);
        if (isDisliked) {
            setIsDisliked(false)
        }
        const requestSave: FeedbackSaveRequest = {
            message_id: pos,
            feedback: !isLiked ? 1 : 0,
            session_id: SESSION_ID.toString(),
        };
        await fetchFeedbackApi(requestSave, abortController.signal);
    };

    const toggleDislike = async () => {

        setIsDisliked(!isDisliked);
        if (isLiked){
            setIsLiked(false); // Reset like
        }
        const requestSave: FeedbackSaveRequest = {
            message_id: pos,
            feedback: !isDisliked ? -1 : 0,
            session_id: SESSION_ID.toString(),
        };
        await fetchFeedbackApi(requestSave, abortController.signal);
    };

    const likeImage = isLiked ? LikeB : LikeW ;
    const dislikeImage = isDisliked ? DisLikeB : DisLikeW;

    const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] = useBoolean(false);
    const filePathTruncationLimit = 50;

    const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
    const [chevronIsExpanded, setChevronIsExpanded] = useState(isRefAccordionOpen);

    const handleChevronClick = () => {
        setChevronIsExpanded(!chevronIsExpanded);
        toggleIsRefAccordionOpen();
      };

    useEffect(() => {
        setChevronIsExpanded(isRefAccordionOpen);
    }, [isRefAccordionOpen]);

    const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
        let citationFilename = "";

        if (citation.filepath && citation.chunk_id) {
            if (truncate && citation.filepath.length > filePathTruncationLimit) {
                const citationLength = citation.filepath.length;
                citationFilename = `${citation.filepath.substring(0, 20)}...${citation.filepath.substring(citationLength -20)} - Part ${parseInt(citation.chunk_id) + 1}`;
            }
            else {
                citationFilename = `${citation.filepath} - Part ${parseInt(citation.chunk_id) + 1}`;
            }
        }
        else {
            citationFilename = `Citation ${index}`;
        }
        return citationFilename;
    }

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    <ReactMarkdown
                        linkTarget="_blank"
                        remarkPlugins={[remarkGfm, supersub]}
                        children={parsedAnswer.markdownFormatText}
                        className={styles.answerText}
                    />
                </Stack.Item>
                <Stack horizontal className={styles.answerFooter}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <button
                          style={{
                            border: 'none',
                            background: 'none',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                          onClick={toggleLike}
                        >
                          <img
                            src={likeImage}
                            alt="Like"
                            aria-hidden="true"
                            style={{ width: '20px' }}
                          />
                        </button>

                        <button
                          style={{
                            border: 'none',
                            background: 'none',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                          onClick={toggleDislike}
                        >
                          <img
                            src={dislikeImage}
                            alt="Dislike"
                            aria-hidden="true"
                            style={{ width: '15px' }}
                          />
                        </button>
                      </div>

{/*                {!!parsedAnswer.citations.length && (
                    <Stack.Item>
                        <Stack style={{width: "100%"}} >
                            <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                <Text
                                    className={styles.accordionTitle}
                                    onClick={toggleIsRefAccordionOpen}
                                    aria-label="Open references"
                                    tabIndex={0}
                                    role="button"
                                >
                                <span>{parsedAnswer.citations.length > 1 ? parsedAnswer.citations.length + " references" : "1 reference"}</span>
                                </Text>
                                <FontIcon className={styles.accordionIcon}
                                onClick={handleChevronClick} iconName={chevronIsExpanded ? 'ChevronDown' : 'ChevronRight'}
                                />
                            </Stack>
                            
                        </Stack>
                    </Stack.Item>
                )}*/}


                </Stack>
                 <Stack horizontal className={styles.answerFooter}>
                    <Stack.Item className={styles.answerDisclaimerContainer}>
                    <span className={styles.answerDisclaimer}>AI-generated content, for more detailed assitant click in "Ask an human"</span>
                    </Stack.Item>
                 </Stack>
                {chevronIsExpanded && 
                    <div style={{ marginTop: 8, display: "flex", flexFlow: "wrap column", maxHeight: "150px", gap: "4px" }}>
                        {parsedAnswer.citations.map((citation, idx) => {
                            return (
                                <span 
                                    title={createCitationFilepath(citation, ++idx)} 
                                    tabIndex={0} 
                                    role="link" 
                                    key={idx} 
                                    onClick={() => onCitationClicked(citation)} 
                                    className={styles.citationContainer}
                                    aria-label={createCitationFilepath(citation, idx)}
                                >
                                    // <div className={styles.citation}>{idx}</div>
                                    {createCitationFilepath(citation, idx, true)}
                                </span>);
                        })}
                    </div>
                }
            </Stack>
        </>
    );
};
