import { Outlet, Link, useNavigate } from "react-router-dom";
import styles from "./Layout.module.css";
import Azure from "../../assets/Azure.svg";
import Seaver from "../../assets/icon_kust.svg";
import { CopyRegular, ChatHelpFilled } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import { Dialog, Stack, TextField } from "@fluentui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { fecthCloseTicket, fetchConversationLink, fetchHelpWeb } from "../../api";
import EventContext from "../../EventContext";
import React from "react";
import { useSystemMessage } from "../../components/SystemMessage/SystemMessageContext";

// import Send from "../../assets/Send.svg";

const Layout = () => {

    
    const abortFuncs = useRef([] as AbortController[]);
    const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false);
    const [copyClicked, setCopyClicked] = useState<boolean>(false);
    const [isHelpDisabled, setHelpDisabled] = useState<boolean>(false);
    const [isCloseDisabled, setCloseDisabled] = useState<boolean>(false);
    const [isCopyDisabled, setCopyDisabled] = useState<boolean>(false);
    const [copyText, setCopyText] = useState<string>("Copy URL");
    const [copyLink, setCopyLink] = useState<string | undefined>(undefined);

    const { setSystemMessage } = useSystemMessage();
    

    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const navigate = useNavigate();
    
    const onLoadSession = () => {
        const helpMode = sessionStorage.getItem('helpMode');
        if(helpMode){
            const helped: boolean = JSON.parse(helpMode).help && JSON.parse(helpMode).role == "support"
            setHelpDisabled(helped)
            const closeDisable: boolean = JSON.parse(helpMode).role != "support"
            setCloseDisabled(closeDisable);
            // loadConversationLink();
        }
    };
    const { closeTriggered } = useContext(EventContext);

    React.useEffect(() => {
        if (closeTriggered) {
            setHelpDisabled(false);
        }
    }, [closeTriggered]);

    const { triggerEvent, eventTriggered } = useContext(EventContext);
    React.useEffect(() => {
        if (eventTriggered) {
            setHelpDisabled(true);
            setSystemMessage('Your request for help has been sent to Admin.');
            setCloseDisabled(true);
        }
    }, [eventTriggered]);

    // const handleShareClick = () => {
    //     setIsSharePanelOpen(true);
    // };

    const handleSharePanelDismiss = () => {
        setIsSharePanelOpen(false);
        setCopyClicked(false);
        setCopyText("Copy URL");
    };
    const loadConversationLink = async ()=>{
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);
        try{
            if(!copyLink){
                const help = await fetchConversationLink(abortController.signal)
                if(help && help.link){
                    setCopyLink(help.link)
                    // setCopyDisabled(true)
                }
            }
            setIsSharePanelOpen(true);
        }catch(e){
            console.log(e)
        } finally {
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
        }

    }
    const handleCopyClick = () => {
        navigator.clipboard.writeText(copyLink!);
        setCopyClicked(true);
    };
    const  handleShareClick = async() => {
        loadConversationLink();
    }
    const  onClickHelp = async() => {
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);
        try{
            const help = await fetchHelpWeb(abortController.signal)
            sessionStorage.setItem('helpMode', JSON.stringify({help: true, role:'user'}));
            onLoadSession();
            triggerEvent();
            setHelpDisabled(true);
            setCookie('accessToken', help.token);
            setSystemMessage('Your request for help has been sent to Admin.');
            setCloseDisabled(true);
            return help
        }catch(e){
            console.log(e)
        } finally {
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
        }
    };

    const  onClickClose = async() => {
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);
        try{
            const close = await fecthCloseTicket(abortController.signal)
            removeCookie('accessToken');
            sessionStorage.clear();
            navigate('/closed');
            return close
        }catch(e){
            console.log(e)
        } finally {
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
        }
    }

    useEffect(() => {
        if (copyClicked) {
            setCopyText("Copied URL");
        }
    }, [copyClicked]);

    useEffect(() => {
        onLoadSession();
    }, []);
    

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Stack horizontal verticalAlign="center"  style={{ width: '100%' }}>
                        <img
                            src={Seaver}
                            className={styles.headerIcon}
                            aria-hidden="true"
                        />
                        <Link to="/" className={styles.headerTitleContainer}>
                            <h1 className={styles.headerTitle}>Kust Assistant</h1>
                        </Link>
                        <div style={{ flex: 1 }}></div>
                        {!!cookies.accessToken && 
                        <Button appearance="primary" icon={<ChatHelpFilled />}   disabled={isHelpDisabled} onClick={onClickHelp} style={{ margin: '15px' }}>
                            Ask an human 
                        </Button>
                        }
                        {!!cookies.accessToken && isHelpDisabled && false &&
                        <Button appearance="primary" icon={<ChatHelpFilled />}  onClick={handleShareClick} style={{ margin: '15px' }}>
                            Continue later
                        </Button>
                        }
                        {!!cookies.accessToken && isHelpDisabled && !isCloseDisabled &&
                        <Button appearance="secondary" icon={<ChatHelpFilled />}  disabled={isCloseDisabled}  onClick={onClickClose} style={{ margin: '15px' }}>
                            Close ticket
                        </Button>
                        }
                    </Stack>
                </div>
            </header>
            <Outlet />
            <Dialog 
                onDismiss={handleSharePanelDismiss}
                hidden={!isSharePanelOpen}
                styles={{
                    
                    main: [{
                        selectors: {
                          ['@media (min-width: 480px)']: {
                            maxWidth: '600px',
                            background: "#FFFFFF",
                            boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                            borderRadius: "8px",
                            maxHeight: '200px',
                            minHeight: '100px',
                          }
                        }
                      }]
                }}
                dialogContentProps={{
                    title: "Copy this link and save it to continue later ",
                    showCloseButton: true
                }}
            >
                <Stack horizontal verticalAlign="center" style={{gap: "8px"}}>
                    <TextField className={styles.urlTextBox} defaultValue={copyLink} readOnly/>
                    <div 
                        className={styles.copyButtonContainer} 
                        role="button" 
                        tabIndex={0} 
                        aria-label="Copy" 
                        onClick={handleCopyClick}
                        onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyClick() : null}
                    >
                        <CopyRegular className={styles.copyButton} />
                        <span className={styles.copyButtonText}>{copyText}</span>
                    </div>
                </Stack>
            </Dialog>
        </div>
    );
};

export default Layout;
